import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import { map } from "./globals";
import { GeoJSON } from "ol/format";

const geoJSONFormat = new GeoJSON();

//land
const tiePointLayer = new VectorLayer();
tiePointLayer.set("name", "Tie Point");

const tieLineLayer = new VectorLayer();
tieLineLayer.set("name", "Tie Line");

const newLayer = new VectorLayer();
newLayer.set("name", "New Feature");

const tiePointSource = new VectorSource();
tiePointLayer.setSource(tiePointSource);
const tieLineSource = new VectorSource();
tieLineLayer.setSource(tieLineSource);
const newSource = new VectorSource();
newLayer.setSource(newSource);


// const drawLayer = new VectorLayer();
// drawLayer.set("name", "New Feature");

// const drawSource = new VectorSource();
// drawLayer.setSource(drawSource);

export function addtechnicaldescriptionlayer() {
  map.addLayer(tiePointLayer);
  map.addLayer(tieLineLayer);
  map.addLayer(newLayer);
}

export function addDrawLayer() {
  
  map.addLayer(newLayer);
}

export function addFeatureToLayer(layerName, feature) {
  map.getLayers().forEach((layer) => {
    if (layer.get("name") === layerName && layer instanceof VectorLayer) {
      const source = layer.getSource();
      source.clear();
      source.addFeature(feature);
    }
  });
}

export function addFeatureToLayerMultiple(layerName, feature) {
  map.getLayers().forEach((layer) => {
    if (layer.get("name") === layerName && layer instanceof VectorLayer) {
      const source = layer.getSource();
      source.addFeature(feature);
    }
  });
}

export function getLayer(layerName) {
  let vlayer;
  map.getLayers().forEach((layer) => {
    if (layer.get("name") === layerName && layer instanceof VectorLayer) {
      vlayer = layer;
    }
  });
  return vlayer;
}

// add export button on button group
export function enabledownloadgeojson() {
  const exportButton = document.createElement("button");
  exportButton.id = "export";
  exportButton.classList.add("btn", "btn-primary", "btn-sm");
  exportButton.title = "Export GeoJSON";
  exportButton.innerHTML = "Export GeoJSON";
  // const exportIcon = document.createElement("i");
  // exportIcon.classList.add("fas", "fa-file-export");
  // exportButton.appendChild(exportIcon);

  exportButton.onclick = function () {
    let features = [];
    parcelSource.forEachFeature((feature) => {
      features.push(feature);
    });

    const geoJSON = geoJSONFormat.writeFeatures(features);
    // window.OLModel.getGeoJSON(JSON.stringify({ geoJSON: geoJSON }));

    // Create a Blob from the GeoJSON string
    const blob = new Blob([geoJSON], { type: "application/json" });

    // Create a URL for the Blob
    const url = URL.createObjectURL(blob);

    // Create a hidden link and attach the Blob URL to it
    const link = document.createElement("a");
    link.href = url;
    link.download = "parcel.geojson";

    // Append the link to the body
    document.body.appendChild(link);

    // Trigger a click on the link to start the download
    link.click();
  };

  const buttonGroup = document.getElementById("buttonGroup");
  buttonGroup.appendChild(exportButton);
}
