import { Overlay, View } from "ol";
import { unByKey } from "ol/Observable";
import { Draw, Modify, Snap } from "ol/interaction";
import { getArea } from "ol/sphere";
import { Style, Fill, Stroke, Text } from "ol/style";
import { map, proj4 } from "./globals";
import { getLayer } from "./vectorlayer";
import { Circle } from "ol/style";
import { GeoJSON, WKT } from "ol/format";
import { extend, createEmpty, getCenter } from "ol/extent";
import Translate from "ol/interaction/Translate";
import Collection from "ol/Collection";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import Feature from "ol/Feature";
import { generatelayerlist } from "./layerlist";
import { randomPolygonParcelStyle, enableSectionLegendList } from "./mapstyle";
import { DragBox } from "ol/interaction";
import { platformModifierKeyOnly } from "ol/events/condition";

let snap = null;
let modify = null;
let draw = null;

const geoJSONFormat = new GeoJSON();
const wktFormat = new WKT();

const drawPolygonStyle = new Style({
  fill: new Fill({
    color: "rgba(255, 255, 255, 0.5)", // semi-transparent fill
  }),
  stroke: new Stroke({
    color: "#ff0000",
    width: 5,
  }),
  image: new Circle({
    radius: 7,
    fill: new Fill({
      color: "#ff0000",
    }),
  }),
});

const drawPointStyle = new Style({
  image: new Circle({
    radius: 5,
    fill: new Fill({
      color: "rgba(255, 255, 255, 0.5)",
    }),
    stroke: new Stroke({
      color: "#ff0000",
      width: 2,
    }),
  }),
});

const drawEndStyle = new Style({
  fill: new Fill({
    color: "rgba(255, 255, 255, 0.5)", // semi-transparent fill
  }),
  stroke: new Stroke({
    color: "#ffcc33",
    width: 5,
  }),
  image: new Circle({
    radius: 5,
    fill: new Fill({
      color: "rgba(255, 255, 255, 0.5)",
    }),
    stroke: new Stroke({
      color: "#ffcc33",
      width: 2,
    }),
  }),
});

const copyStyle = new Style({
  fill: new Fill({
    color: "rgba(255, 0, 0, 0.3)", // semi-transparent red fill with increased opacity
  }),
  stroke: new Stroke({
    color: "#00ff00", // black stroke
    width: 5,
  }),
  image: new Circle({
    radius: 5,
    fill: new Fill({
      color: "rgba(255, 0, 0, 0.7)", // semi-transparent red fill with increased opacity
    }),
    stroke: new Stroke({
      color: "#00ff00", // black stroke
      width: 2,
    }),
  }),
});

const helpTooltipElement = document.createElement("div");
helpTooltipElement.classList.add("ol-tooltip", "hidden");
document.body.appendChild(helpTooltipElement);

const helpTooltip = new Overlay({
  element: helpTooltipElement,
  offset: [15, 0],
  positioning: "center-left",
});
map.addOverlay(helpTooltip);

const measureTooltipElement = document.createElement("div");
measureTooltipElement.classList.add("ol-tooltip", "ol-tooltip-measure");
document.body.appendChild(measureTooltipElement);

const measureTooltip = new Overlay({
  element: measureTooltipElement,
  offset: [0, -15],
  positioning: "bottom-center",
  stopEvent: false,
  insertFirst: false,
});

map.addOverlay(measureTooltip);

export function enableDragPolygon() {
  //if button already exist
  const dragbutton = document.getElementById("dragPolygonButton");
  if (dragbutton) {
    return;
  }
  const dragPolygonButton = document.createElement("button");
  dragPolygonButton.textContent = "Drag Polygon";
  dragPolygonButton.id = "dragPolygonButton";
  dragPolygonButton.onclick = function () {
    startDragPolygon();
  };
  const buttonGroup = document.getElementById("buttonGroup");
  buttonGroup.appendChild(dragPolygonButton);
}

export function startDragPolygon() {
  const drawlayer = getLayer("New Feature");
  const drawSource = drawlayer.getSource();
  const features = drawSource.getFeatures();
  if (features.length === 0) {
    alert("No features found");
    return;
  }

  const reprojectedLayer = getLayer("Reprojected Feature");

  if (!reprojectedLayer) {
    const feature = features[0];

    // Create a copy of the feature
    const featureCopy = new Feature(feature.getGeometry().clone());

    // Apply the copyStyle to the copied feature
    featureCopy.setStyle(copyStyle);

    // Create a new source with the copy of the feature
    const copySource = new VectorSource();
    copySource.addFeature(featureCopy);

    // Create a copy of the layer with the new source
    const copyLayer = new VectorLayer({
      source: copySource,
    });
    copyLayer.set("name", "Reprojected Feature");
    map.addLayer(copyLayer);
    generatelayerlist(map);

    // Enable dragging on the new copy
    const dragInteraction = new Translate({
      features: new Collection([featureCopy]),
    });
    map.addInteraction(dragInteraction);
  } else {
    const reprojectedSource = reprojectedLayer.getSource();
    const reprojectedFeatures = reprojectedSource.getFeatures();
    const feature = reprojectedFeatures[0];

    // Enable dragging on the existing reprojected feature
    const dragInteraction = new Translate({
      features: new Collection([feature]),
    });
    map.addInteraction(dragInteraction);
  }
}

export function disableDragPolygonFeature() {
  const dragbutton = document.getElementById("dragPolygonButton");
  if (dragbutton) {
    dragbutton.remove();
  }
}

export function enableGetCoordinatesButton() {
  const getCoordinatesButton = document.createElement("button");
  getCoordinatesButton.textContent = "Get Coordinates";
  getCoordinatesButton.id = "getCoordinatesButton";
  getCoordinatesButton.onclick = function () {
    getCoordinatesOfMouseClick();
  };
  const buttonGroup = document.getElementById("buttonGroup");
  buttonGroup.appendChild(getCoordinatesButton);
}

export function getCoordinatesOfMouseClick() {
  map.on("click", function (evt) {
    const coords = evt.coordinate;
    const projection = map.getView().getProjection();
    // const coordString = coords[0].toFixed(2) + ", " + coords[1].toFixed(2);
    const coordinatesWGS84 = proj4(projection.code_, "EPSG:4326", coords);
    alert(coordinatesWGS84);
  });
}

export function enableEditMode() {
  enableClearDrawFeature();
  startModify();
}

export function addLandParcelFeatureToLayer(geojsonstring, projection) {
  const features = geoJSONFormat.readFeatures(geojsonstring);
  if (features.length === 0) {
    alert("No features found");
    return;
  }

  const landLayer = new VectorLayer();
  const landSource = new VectorSource();

  landLayer.setSource(landSource);

  landLayer.setStyle(copyStyle);
  landSource.clear();
  landSource.addFeatures(features);
  landLayer.set("name", "Land Parcel");

  const newprojectedview = new View({
    projection: projection,
    zoom: 5,
  });

  map.setView(newprojectedview);

  map.addLayer(landLayer);
  generatelayerlist(map);

  let extent = createEmpty();

  features.forEach((feature) => {
    extend(extent, feature.getGeometry().getExtent());
  });

  let center = getCenter(extent);

  map.getView().setCenter(center);
  map.getView().fit(extent);
  let currentResolution = map.getView().getResolution();
  map.getView().setResolution(currentResolution * 2);
}

export function addFeatureToLayer(geojsonstring) {
  // console.log(JSON.stringify(geojsonstring));
  const features = geoJSONFormat.readFeatures(geojsonstring);

  if (features.length === 0) {
    alert("No features found");
    return;
  }
  const drawlayer = getLayer("New Feature");
  const drawSource = drawlayer.getSource();
  drawlayer.setStyle(drawEndStyle);
  drawSource.clear();
  drawSource.addFeatures(features);

  let extent = createEmpty();

  features.forEach((feature) => {
    extend(extent, feature.getGeometry().getExtent());
  });

  let center = getCenter(extent);

  map.getView().setCenter(center);
  map.getView().fit(extent);
  let currentResolution = map.getView().getResolution();
  map.getView().setResolution(currentResolution * 2);

  disableDrawFeature();
}

export function addReprojectedFeatureToLayer(reprojectedgeojsonstring) {
  const reprojectedFeatures = geoJSONFormat.readFeatures(
    reprojectedgeojsonstring
  );

  // Create a new source with the copy of the feature
  const copySource = new VectorSource();

  // Create a copy of the layer with the new source
  const copyLayer = new VectorLayer({
    source: copySource,
  });
  copyLayer.set("name", "Reprojected Feature");
  copyLayer.setStyle(copyStyle);
  map.addLayer(copyLayer);
  copySource.addFeatures(reprojectedFeatures);
  generatelayerlist(map);
}
//button for getdrawingdata
export function getDrawingDataButton(type) {
  const getDrawingDataButton = document.createElement("button");
  getDrawingDataButton.textContent = `Get Drawing Data ${type}`;
  getDrawingDataButton.id = "getDrawingDataButton";
  getDrawingDataButton.onclick = function () {
    if (type == "single") {
      const result = getDrawingData();
      console.log(result);
    } else {
      const result = getDrawingDataMultipleFeature();
      console.log(result);
    }
  };
  const buttonGroup = document.getElementById("buttonGroup");
  buttonGroup.appendChild(getDrawingDataButton);
}

export function getDrawingData() {
  const drawlayer = getLayer("New Feature");
  const features = drawlayer.getSource().getFeatures();
  const geojson = geoJSONFormat.writeFeaturesObject(features);
  const geowkt = wktFormat.writeFeaturesText(features);

  // Get the projection of the features
  const projection = map.getView().getProjection();
  const projectioncode = projection.getCode();

  const reprojectedLayer = getLayer("Reprojected Feature");
  let reprojectedgeojson = null;
  let reprojectedgeowkt = null;
  if (reprojectedLayer) {
    const reprojectedFeatures = reprojectedLayer.getSource().getFeatures();
    reprojectedgeojson = geoJSONFormat.writeFeaturesObject(reprojectedFeatures);
    reprojectedgeowkt = wktFormat.writeFeaturesText(reprojectedFeatures);
  }

  disableDrawFeature();
  disableClearFeature();
  disableDragPolygonFeature();
  stopDrawing();
  stopModify();

  return {
    geojson,
    geowkt,
    reprojectedgeojson,
    reprojectedgeowkt,
    projectioncode,
  };
}

export function getDrawingDataMultipleFeature() {
  const drawlayer = getLayer("New Feature");
  const features = drawlayer.getSource().getFeatures();
  features.forEach((feature) => {
    feature.set("geowkt", wktFormat.writeFeature(feature));
  });
  const geojson = geoJSONFormat.writeFeaturesObject(features);
  const geowkt = wktFormat.writeFeaturesText(features);

  // Get the projection of the features
  const projection = map.getView().getProjection();
  const projectioncode = projection.getCode();

  const reprojectedLayer = getLayer("Reprojected Feature");
  let reprojectedgeojson = null;
  let reprojectedgeowkt = null;
  if (reprojectedLayer) {
    const reprojectedFeatures = reprojectedLayer.getSource().getFeatures();
    reprojectedFeatures.forEach((feature) => {
      feature.set("geowkt", wktFormat.writeFeature(feature));
    });
    reprojectedgeojson = geoJSONFormat.writeFeaturesObject(reprojectedFeatures);
    reprojectedgeowkt = wktFormat.writeFeaturesText(reprojectedFeatures);
  }

  disableDrawFeature();
  disableClearFeature();
  disableDragPolygonFeature();
  stopDrawing();
  stopModify();

  return {
    geojson,
    geowkt,
    reprojectedgeojson,
    reprojectedgeowkt,
    projectioncode,
  };
}

export function enableClearDrawFeature() {
  const drawlayer = getLayer("New Feature");
  const drawSource = drawlayer.getSource();
  const feature = drawSource.getFeatures()[0];
  if (feature) {
    //add clear feature button
    const clearButton = document.createElement("button");
    clearButton.textContent = "Clear Feature";
    clearButton.id = "clearButton";
    clearButton.onclick = function () {
      const geomtype = drawSource.getFeatures()[0].getGeometry().getType();
      drawSource.clear();
      measureTooltipElement.innerHTML = "";
      stopModify();
      enableDrawFeature(geomtype);
    };
    const buttonGroup = document.getElementById("buttonGroup");
    buttonGroup.appendChild(clearButton);
  } else {
    disableClearFeature();
  }
}

export function disableClearFeature() {
  const clearButton = document.getElementById("clearButton");
  if (clearButton) {
    clearButton.remove();
  }
}

export function disableDrawFeature() {
  const drawButton = document.getElementById("drawButton");
  if (drawButton) {
    drawButton.remove();
  }
}

export function enableDrawFeature(featuretype, ismultiple = false) {
  // Create a new button element
  const drawButton = document.createElement("button");
  drawButton.textContent = "Draw Feature";
  drawButton.id = "drawButton";

  initializeDrawFeature(featuretype);
  drawButton.onclick = function () {
    // Add your drawing functionality here
    startDrawing();
  };
  // Add the new button to the button group
  const buttonGroup = document.getElementById("buttonGroup");
  buttonGroup.appendChild(drawButton);
}

// const drawLayer = new VectorLayer();
// const drawSource = new VectorSource();
// drawLayer.setSource(drawSource);
// map.addLayer(drawLayer);

function initializeDrawFeature(featuretype) {
  const drawlayer = getLayer("New Feature");
  const drawSource = drawlayer.getSource();
  snap = new Snap({ source: drawSource });
  modify = new Modify({ source: drawSource });
  draw = new Draw({
    source: drawSource,
    type: featuretype,
    style: featuretype === "Polygon" ? drawPolygonStyle : drawPointStyle,
  });

  draw.on("drawstart", (evt) => {
    const sketch = evt.feature;
    let tooltipCoord = evt.coordinate;
    sketch.on("change", function (evt) {
      if (featuretype === "Point") {
        return;
      }

      const geom = evt.target.getGeometry();
      const output = formatArea(geom);
      tooltipCoord = geom.getInteriorPoint().getCoordinates();
      measureTooltipElement.innerHTML = output;
      measureTooltip.setPosition(tooltipCoord);
    });
  });

  draw.on("drawend", (evt) => {
    const feature = evt.feature;

    feature.setStyle(drawEndStyle);

    stopDrawing();
    startModify();
  });

  modify.on("modifystart", (evt) => {
    const sketch = evt.features.getArray()[0];

    let tooltipCoord = evt.coordinate;
    sketch.on("change", function (evt) {
      if (featuretype === "Point") {
        return;
      }
      const geom = evt.target.getGeometry();
      const output = formatArea(geom);
      tooltipCoord = geom.getInteriorPoint().getCoordinates();
      measureTooltipElement.innerHTML = output;
      measureTooltip.setPosition(tooltipCoord);
    });
  });

  map.getViewport().addEventListener("mouseout", () => {
    helpTooltipElement.classList.add("hidden");
  });

  return {
    draw,
    snap,
    modify,
  };
}

function startDrawing() {
  map.addInteraction(snap);
  map.addInteraction(draw);
  map.on("pointermove", drawpointerMoveHandler);
}

function stopDrawing() {
  map.removeInteraction(snap);
  map.removeInteraction(draw);
  unByKey(map.on("pointermove", drawpointerMoveHandler));
  helpTooltipElement.classList.add("hidden");
}

function startModify() {
  map.addInteraction(modify);
  map.on("pointermove", modifypointerMoveHandler);
}

function stopModify() {
  map.removeInteraction(modify);
  unByKey(map.on("pointermove", modifypointerMoveHandler));
}

function drawpointerMoveHandler(evt) {
  let helpMsg = "Click to add point";
  let tooltipCoord = evt.coordinate;
  helpTooltipElement.innerHTML = helpMsg;
  helpTooltip.setPosition(tooltipCoord);
  helpTooltipElement.classList.remove("hidden");
}

function modifypointerMoveHandler(evt) {
  let pixel = map.getEventPixel(evt.originalEvent);
  let hit = map.hasFeatureAtPixel(pixel);
  let helpMsg = "Drag to modify feature";
  if (hit) {
    helpTooltipElement.innerHTML = helpMsg;
    helpTooltipElement.classList.remove("hidden");
    if (evt.dragging) {
      helpMsg = "Continue dragging to modify feature";
      helpTooltipElement.innerHTML = helpMsg;
      helpTooltipElement.classList.remove("hidden");
    }
  } else {
    helpTooltipElement.innerHTML = "";
    helpTooltipElement.classList.add("hidden");
  }

  let tooltipCoord = evt.coordinate;
  helpTooltip.setPosition(tooltipCoord);
}

function formatArea(polygon) {
  const area = getArea(polygon);
  // let output;
  // if (area > 10000) {
  //   output = Math.round((area / 1000000) * 100) / 100 + ' ' + 'km<sup>2</sup>';
  // } else {
  //   output = Math.round(area * 100) / 100 + ' ' + 'm<sup>2</sup>';
  // }
  const output = Math.round(area * 100) / 100;
  return output.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " " + "sqm";
}

let selected = null;
let originalStyle = null;
let startPoint, endPoint;

// Create a DragBox interaction
let dragBox = new DragBox({
  condition: platformModifierKeyOnly,
});

let clickHandler = null;
let startDragHandler = null
let endDragHandler = null;

export function endMultipleSelection() {
  unByKey(map.on("click", clickHandler));
  map.removeInteraction(dragBox);
  unByKey(dragBox.on("boxstart", startDragHandler));
  unByKey(dragBox.on("boxend", endDragHandler));
  
}

export function startMultipleSelection() {
  let section = parseInt(prompt("Enter Section Number"), 10);
  if (!Number.isInteger(section)) {
    alert("Section is not an integer");
    return;
  }

  if (!section) return;

  let startparcelno = parseInt(prompt("Enter Start Parcel No."), 10);

  if (!Number.isInteger(startparcelno)) {
    alert("Start Parcel No. is not an integer");
    return;
  }

  if (!startparcelno) return;

  const { fillColor } = randomPolygonParcelStyle();
  const params = {
    section: section,
    startparcelno: startparcelno,
    fillColor: fillColor,
  };

  enableSectionLegendList(params);

  

  clickHandler = function (evt) {
    addSelectedFeatureToSectionListHandler(evt, params);
  }
  map.on("click", clickHandler);
  //multi select feature like click and drag

  // Add the DragBox interaction to the map
  map.addInteraction(dragBox);

  startDragHandler = function (event) {
    startSectionDraggingHandler(event);
  };
  dragBox.on("boxstart", startDragHandler);

  endDragHandler = function (event) {
    endSectionDraggingHandler(event, params);
  };
  dragBox.on("boxend", endDragHandler);
}

export function changeSectionSelection(params) {
  endMultipleSelection();
  enableSectionLegendList(params);

  clickHandler = function (evt) {
    addSelectedFeatureToSectionListHandler(evt, params);
  }
  map.on("click", clickHandler);
  //multi select feature like click and drag

  // Add the DragBox interaction to the map
  map.addInteraction(dragBox);

  startDragHandler = function (event) {
    startSectionDraggingHandler(event);
  };
  dragBox.on("boxstart", startDragHandler);

  endDragHandler = function (event) {
    endSectionDraggingHandler(event, params);
  };
  dragBox.on("boxend", endDragHandler);
}

function startSectionDraggingHandler(event) {
  startPoint = event.coordinate;
}

function endSectionDraggingHandler(event, params) {
  const { section, startparcelno, fillColor } = params;
  endPoint = event.coordinate;
  let extent = dragBox.getGeometry().getExtent();

  const drawlayer = getLayer("New Feature");
  const drawSource = drawlayer.getSource();
  const features = drawSource.getFeatures();
  if (features.length === 0) {
    alert("No features found");
    return;
  }

  let intersectingFeatures = [];
  drawSource.forEachFeatureIntersectingExtent(extent, function (feature) {
    let featureCenter = getCenter(feature.getGeometry().getExtent());
    intersectingFeatures.push({
      feature: feature,
      x: featureCenter[0],
    });
  });

  // Determine the direction of sorting
  let direction = startPoint[0] < endPoint[0] ? 1 : -1;

  // Sort the features by x-coordinate of the center
  intersectingFeatures.sort(function (a, b) {
    return direction * (a.x - b.x);
  });

  // Log the sorted features
  intersectingFeatures.forEach(function (item) {
    let originalStyle = item.feature.getStyle();
    let selectedfeature = item.feature;
    if (selectedfeature) {
      originalStyle[0].setFill(new Fill({ color: fillColor }));

      selectedfeature.setStyle(originalStyle);

      const cadastrallotno = selectedfeature.get("cadastrallotno");
      selectedfeature.set("section", section);
      selectedfeature.set("fillColor", fillColor);
      selectedfeature.set("startparcelno", startparcelno);
      let parcelno = selectedfeature.get("parcelno");
      // If the selected feature doesn't have a parcelno yet, or if it's an empty string, set it to a value
      if (!parcelno || parcelno === "") {
        parcelno = getLastParcelNo(features, section, startparcelno);
        const sectionstyle = selectedfeature.getStyle();
        sectionstyle[sectionstyle.length - 1]
          .getText()
          .setText(cadastrallotno + "- Parcel No." + parcelno);
        selectedfeature.set("parcelno", parcelno);
        5;
      } else {
        // If the selected feature already has a parcelno, set it to an empty string
        const sectionstyle = selectedfeature.getStyle();
        sectionstyle[sectionstyle.length - 1].getText().setText(cadastrallotno);
        originalStyle[0].setFill(
          new Fill({ color: "rgba(255, 204, 51, 0.2)" })
        );
        selectedfeature.setStyle(originalStyle);
        selectedfeature.set("parcelno", "");
      }
    }
  });
}

function addSelectedFeatureToSectionListHandler(evt, params) {
  const { section, startparcelno, fillColor } = params;
  const drawlayer = getLayer("New Feature");
  const drawSource = drawlayer.getSource();
  const features = drawSource.getFeatures();
  if (features.length === 0) {
    alert("No features found");
    return;
  }
  let selectedfeature = null;

  map.forEachFeatureAtPixel(evt.pixel, function (f) {
    originalStyle = f.getStyle();
    selectedfeature = f;
    return true;
  });
  if (selectedfeature) {
    originalStyle[0].setFill(new Fill({ color: fillColor }));

    selectedfeature.setStyle(originalStyle);

    const cadastrallotno = selectedfeature.get("cadastrallotno");
    selectedfeature.set("section", section);
    let parcelno = selectedfeature.get("parcelno");
    // If the selected feature doesn't have a parcelno yet, or if it's an empty string, set it to a value
    if (!parcelno || parcelno === "") {
      parcelno = getLastParcelNo(features, section, startparcelno);
      const sectionstyle = selectedfeature.getStyle();
      sectionstyle[sectionstyle.length - 1]
        .getText()
        .setText(cadastrallotno + "- Parcel No." + parcelno);
      selectedfeature.set("parcelno", parcelno);
      5;
    } else {
      // If the selected feature already has a parcelno, set it to an empty string
      const sectionstyle = selectedfeature.getStyle();
      sectionstyle[sectionstyle.length - 1].getText().setText(cadastrallotno);
      originalStyle[0].setFill(new Fill({ color: "rgba(255, 204, 51, 0.2)" }));
      selectedfeature.setStyle(originalStyle);
      selectedfeature.set("parcelno", "");
    }
  }
}

export function endParcelTaggingFeature() {
  unByKey(map.on("pointermove", highlightSelectedFeatureHandler));
  unByKey(map.on("click", tagSelectedFeatureHandler));
}

export function enableParcelTaggingFeature() {
  // const endparcelTaggingButton = document.createElement("button");
  // endparcelTaggingButton.textContent = "End Parcel Tagging";
  // endparcelTaggingButton.id = "endparcelTaggingButton";
  // endparcelTaggingButton.onclick = function () {
  //   unByKey(map.on("pointermove", highlightSelectedFeatureHandler));
  //   unByKey(map.on("click", tagSelectedFeatureHandler));
  //   //remove endparcelTaggingButton
  //   endparcelTaggingButton.remove();
  //   window.getGeoJSON();

  //   // window.OlModel.endTaggingParcel(featuredata);
  // };
  // // const buttonGroup = document.getElementById("buttonGroup");
  // // buttonGroup.appendChild(endparcelTaggingButton);

  map.on("pointermove", highlightSelectedFeatureHandler);
  map.on("click", tagSelectedFeatureHandler);
  // };

  // const buttonGroup = document.getElementById("buttonGroup");
  // buttonGroup.appendChild(parcelTaggingButton);
}

function highlightSelectedFeatureHandler(e) {
  const highlightStyle = new Style({
    stroke: new Stroke({
      color: "yellow",
      width: 3,
    }),
    fill: new Fill({
      color: "rgba(255, 255, 0, 0.1)",
    }),
  });

  if (selected !== null) {
    selected.setStyle(originalStyle);
    selected = null;
  }
  map.forEachFeatureAtPixel(e.pixel, function (f) {
    selected = f;
    originalStyle = f.getStyle();
    // const hoverstyle = originalStyle;
    // hoverstyle.push(highlightStyle);
    // console.log(originalStyle);
    const cadastrallotno = selected.get("cadastrallotno");
    let parcelno = selected.get("parcelno");
    const label = new Text({
      text: cadastrallotno + (parcelno ? "- Parcel No." + parcelno : ""),
      fill: new Fill({
        color: "#000000",
      }),
      stroke: new Stroke({
        color: "#ffffff",
        width: 3,
      }),
    });
    highlightStyle.setText(label);
    f.setStyle(highlightStyle);
    return true;
  });
}

function tagSelectedFeatureHandler(evt) {
  const drawlayer = getLayer("New Feature");
  const drawSource = drawlayer.getSource();
  const features = drawSource.getFeatures();

  if (features.length === 0) {
    alert("No features found");
    return;
  }
  let selectedfeature = null;

  map.forEachFeatureAtPixel(evt.pixel, function (f) {
    f.setStyle(originalStyle);
    selectedfeature = f;

    return true;
  });
  if (selectedfeature) {
    const cadastrallotno = selectedfeature.get("cadastrallotno");
    const section = selectedfeature.get("section");
    const startparcelno = drawlayer.get(section);
    let parcelno = selectedfeature.get("parcelno");

    // If the selected feature doesn't have a parcelno yet, or if it's an empty string, set it to a value
    if (!parcelno || parcelno === "") {
      parcelno = getLastParcelNo(features, section, startparcelno);
      const sectionstyle = selectedfeature.getStyle();

      sectionstyle[sectionstyle.length - 1]
        .getText()
        .setText(cadastrallotno + "- Parcel No." + parcelno);
      selectedfeature.set("parcelno", parcelno);
    } else {
      // If the selected feature already has a parcelno, set it to an empty string
      const sectionstyle = selectedfeature.getStyle();
      sectionstyle[sectionstyle.length - 1].getText().setText(cadastrallotno);
      selectedfeature.set("parcelno", "");
    }
  }
}

function getLastParcelNo(features, section, startparcelno) {
  //get last no of features in section
  let lastparcelno = startparcelno;
  features.forEach((feature) => {
    if (feature.get("section") === section) {
      const cadastrallotno = feature.get("cadastrallotno");
      let parcelno = feature.get("parcelno");
      if (parcelno >= lastparcelno) {
        parcelno++;
        lastparcelno = parcelno;
      }
    }
  });
  return lastparcelno;
}
