import TileLayer from "ol/layer/Tile";
import BingMaps from "ol/source/BingMaps";
import OSM from "ol/source/OSM";
import { TileArcGISRest, XYZ } from "ol/source";
import { map } from "./globals";

export const bingLayer = new TileLayer({
  source: new BingMaps({
    key: "ArXh_Fl3zUcdTK90iBzQ1IS3M9Ln-j1S7F6OZdF5pAuzGEkq3t0UzmY1cFaZe8eD",
    imagerySet: "AerialWithLabelsOnDemand",
  }),
});
bingLayer.set("name", "Bing Maps Layer");

export const osmLayer = new TileLayer({
  source: new OSM(),
});
osmLayer.set("name", "OSM Layer");

// ArcGIS base map
// export const arcgisSatLayer = new TileLayer({
//   source: new TileArcGISRest({
//     url: "https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer",
//   }),
// });
// arcgisSatLayer.set("name", "ArcGIS Layer");

// Google base map
export const googleSatLayer = new TileLayer({
  source: new XYZ({
    url: "https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}",
  }),
});
googleSatLayer.set("name", "Google Layer");

map.addLayer(bingLayer);
// map.addLayer(arcgisSatLayer);
map.addLayer(googleSatLayer);
map.addLayer(osmLayer);
bingLayer.setVisible(false);
osmLayer.setVisible(false);

export function hidebasemaps(){
  bingLayer.setVisible(false);
  googleSatLayer.setVisible(false);
  osmLayer.setVisible(false);
}

export function showgooglebasemap(){
  bingLayer.setVisible(false);
  osmLayer.setVisible(false);
}

