import { Style, Fill, Stroke, Text } from "ol/style";
import Point from "ol/geom/Point";
import Circle from "ol/style/Circle";
import { changeSectionSelection } from "./drawing";

export const randomPolygonParcelStyle = function () {
  // Generate random RGB values
  let r = Math.floor(Math.random() * 256);
  let g = Math.floor(Math.random() * 256);
  let b = Math.floor(Math.random() * 256);

  // Create the fill color string
  let fillColor = "rgba(" + r + "," + g + "," + b + ",0.5)"; // 0.5 is the opacity

  // var geometry = feature.getGeometry();
  // var featureproperties = feature.getProperties();
  // var cadastrallotno = feature.getProperties().cadastrallotno;
  // var sectionstyle = new Style({
  //     fill: new Fill({
  //       color: fillColor, // semi-transparent fill
  //     }),
  //     stroke: new Stroke({
  //       color: "#ffcc33",
  //       width: 5,
  //     }),
  //   });

  // sectionstyle.push(
  //   new Style({
  //     geometry: new Point(geometry.getInteriorPoint().getCoordinates()),
  //     text: new Text({
  //       text: section,
  //       fill: new Fill({ color: "#000" }),
  //       stroke: new Stroke({ color: "#fff", width: 2 }),
  //     }),
  //   })
  // );

  // if (geometry.getType() === "Polygon") {
  //   // Get the coordinates of the Polygon's exterior ring
  //   var coordinates = geometry.getCoordinates()[0];

  //   coordinates.forEach(function (coord, i) {
  //     if (featureproperties.properties[i]) {
  //       var text =
  //         featureproperties.properties[i].frompoint +
  //         "-" +
  //         featureproperties.properties[i].topoint +
  //         "\n" +
  //         featureproperties.properties[i].ns +
  //         " " +
  //         featureproperties.properties[i].deg +
  //         "° " +
  //         featureproperties.properties[i].min +
  //         "' " +
  //         featureproperties.properties[i].ew +
  //         " " +
  //         featureproperties.properties[i].distance +
  //         " m";

  //       var start = coordinates[i];
  //       var end = coordinates[(i + 1) % coordinates.length];

  //       // Calculate the midpoint between this coordinate and the next one
  //       var dx = end[0] - start[0];
  //       var dy = end[1] - start[1];
  //       var midpoint = [start[0] + dx / 2, start[1] + dy / 2];

  //       // Calculate the rotation from the positive x-axis to the line segment
  //       var rotation = Math.atan2(dy, dx);

  //       styles.push(
  //         new Style({
  //           geometry: new Point(midpoint),
  //           text: new Text({
  //             text: text,
  //             fill: new Fill({ color: "#000" }),
  //             stroke: new Stroke({ color: "#fff", width: 2 }),
  //             rotation: -rotation,
  //             offsetY: -15, // Adjust this value as needed
  //           }),
  //         })
  //       );
  //       //cadastrallotno label in center of polygon
  //       styles.push(
  //         new Style({
  //           geometry: new Point(geometry.getInteriorPoint().getCoordinates()),
  //           text: new Text({
  //             text: cadastrallotno,
  //             fill: new Fill({ color: "#000" }),
  //             stroke: new Stroke({ color: "#fff", width: 2 }),
  //           }),
  //         })
  //       );
  //     }
  //   });
  // }

  return {
    fillColor,
  };
};

export const stylePolygonParcel = function (feature) {
  var geometry = feature.getGeometry();
  var featureproperties = feature.getProperties();
  console.log(featureproperties);
  var cadastrallotno = feature.getProperties().cadastrallotno;
  var styles = [
    // polygon
    new Style({
      fill: new Fill({
        color: "rgba(255, 255, 255, 0.5)", // semi-transparent fill
      }),
      stroke: new Stroke({
        color: "#ffcc33",
        width: 5,
      }),
    }),
  ];

  // let text = new Text({
  //   text: cadastrallotno, // Replace this with your label
  //   fill: Fill({
  //     color: '#000'
  //   }),
  //   stroke: Stroke({
  //     color: '#fff',
  //     width: 2
  //   })
  // });
  // console.log("TEST");
  // styles.setText(text);

  if (geometry.getType() === "Polygon") {
    // Get the coordinates of the Polygon's exterior ring
    var coordinates = geometry.getCoordinates()[0];
    coordinates.forEach(function (coord, i) {
      if (featureproperties.technicaldescriptions[i]) {
        var text =
          featureproperties.technicaldescriptions[i].frompoint +
          "-" +
          featureproperties.technicaldescriptions[i].topoint +
          "\n" +
          featureproperties.technicaldescriptions[i].ns +
          " " +
          featureproperties.technicaldescriptions[i].deg +
          "° " +
          featureproperties.technicaldescriptions[i].min +
          "' " +
          featureproperties.technicaldescriptions[i].ew +
          " " +
          featureproperties.technicaldescriptions[i].distance +
          " m";

        var start = coordinates[i];
        var end = coordinates[(i + 1) % coordinates.length];

        // Calculate the midpoint between this coordinate and the next one
        var dx = end[0] - start[0];
        var dy = end[1] - start[1];
        var midpoint = [start[0] + dx / 2, start[1] + dy / 2];

        // Calculate the rotation from the positive x-axis to the line segment
        var rotation = Math.atan2(dy, dx);

        styles.push(
          new Style({
            geometry: new Point(midpoint),
            text: new Text({
              text: text,
              fill: new Fill({ color: "#000" }),
              stroke: new Stroke({ color: "#fff", width: 2 }),
              rotation: -rotation,
              offsetY: -15, // Adjust this value as needed
            }),
          })
        );
        //cadastrallotno label in center of polygon
        styles.push(
          new Style({
            geometry: new Point(geometry.getInteriorPoint().getCoordinates()),
            text: new Text({
              text: cadastrallotno,
              fill: new Fill({ color: "#000" }),
              stroke: new Stroke({ color: "#fff", width: 2 }),
            }),
          })
        );
      }
    });
  }

  return styles;
};

export const tieLineStyle = new Style({
  stroke: new Stroke({
    color: "#000000",
    width: 5,
    lineDash: [10, 10], // This will create a dotted line with 10px dots and 10px spaces
  }),
});

export const tiepointStyle = new Style({
  image: new Circle({
    radius: 5,
    fill: new Fill({
      color: "#ff0000",
    }),
  }),
});

export function enableSectionLegendList(params) {
  const { section, startparcelno, fillColor } = params;
  var sectionlist = document.getElementById("sectionLegendList");

  // Check if the section already exists
  var existingSection = sectionlist.querySelector(
    `li[data-section="${section}"]`
  );
  if (existingSection) {
    return; // If the section already exists, exit the function
  }

  // Check if the header already exists
  if (!document.getElementById("sectionLegendHeader")) {
    var header = document.createElement("h2");
    header.id = "sectionLegendHeader";
    header.textContent = "Section Legend";
    sectionlist.prepend(header);
  }

  var sectionelement = document.createElement("li");
  sectionelement.dataset.section = section; // Add a data attribute to the section element

  // Create a color square
  var colorSquare = document.createElement("span");
  colorSquare.style.backgroundColor = fillColor;
  colorSquare.style.display = "inline-block";
  colorSquare.style.width = "20px";
  colorSquare.style.height = "20px";
  colorSquare.style.marginRight = "5px";

  // Append the color square and the section label to the list item
  sectionelement.appendChild(colorSquare);
  sectionelement.appendChild(document.createTextNode(section));

  sectionlist.appendChild(sectionelement);

  // Remove the 'active' class from all sections
  var sections = document.querySelectorAll("#sectionLegendList li");
  sections.forEach(function (section) {
    section.classList.remove("active");
  });

  // Add the 'active' class to the new section
  sectionelement.classList.add("active");

  sectionelement.addEventListener("click", handleSectionClick);
  // // Add a click event listener to the section
  // sectionelement.addEventListener("click", function () {
  //   // Remove the 'active' class from all sections
  //   var sections = document.querySelectorAll("#sectionLegendList li");
  //   sections.forEach(function (section) {
  //     section.classList.remove("active");
  //   });

  //   // Add the 'active' class to the clicked section
  //   this.classList.add("active");

  //   // Call the changeSectionSelection function
  //   changeSectionSelection(params);
  // });
}

export function handleSectionClick() {
  // Remove the 'active' class from all sections
  var sections = document.querySelectorAll("#sectionLegendList li");
  sections.forEach(function (section) {
    section.classList.remove("active");
  });

  // Add the 'active' class to the clicked section
  this.classList.add("active");

  // Call the changeSectionSelection function
  changeSectionSelection(params);
}
