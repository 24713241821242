export function generatelayerlist(map) {
  const layers = map.getLayers().getArray();
  const layerNames = layers.map((layer) => layer.get("name"));
  const container = document.getElementById("layerList");
  container.innerHTML = "";
  layers.forEach((layer, index) => {
   
    const checkbox = document.createElement("input");
    checkbox.type = "checkbox";
    checkbox.id = "checkbox" + index;
    checkbox.checked = layer.getVisible();

    checkbox.addEventListener("change", function () {
      layer.setVisible(this.checked);
    });

    const label = document.createElement("label");
    label.htmlFor = checkbox.id;
    label.appendChild(document.createTextNode(layerNames[index]));

    container.appendChild(checkbox);
    container.appendChild(label);
    container.appendChild(document.createElement("br"));
  });
}
